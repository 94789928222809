.App {
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: #fbfcf8;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover,
.drop-container.drag-active {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title,
.drop-container.drag-active .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}

input[type="file"] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
}

input[type="file"]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
  background: #0d45a5;
}

button[type="submit"] {
  width: 100%;
  height: 51px;
  margin-top: 16px;
  border-radius: 8px;
  background: #084cdf;
  color: #fff;
}
button[type="submit"]:active {
  transform: scale(0.98);
}

.loadingDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
}

/* h1 {
  color: #333;
}

label {
  font-size: 1.2rem;
}

input[type="file"] {
  padding: 0.5rem;
  border: 1px solid #007bff;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  margin: 10px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  cursor: pointer;
  margin: 10px;
} */

form {
  width: 30%;
  margin: auto;
}
